import { useFormContext, Controller } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import {
  UploadProps,
  UploadAvatar,
  UploadBox,
  Upload,
  UploadBasicInput,
} from '../upload';
import useLocales from '#/hooks/useLocales';
import UploadButton from '#/components/shared/upload/UploadButton';

interface Props extends Omit<UploadProps, 'file'> {
  name: string;
  multiple?: boolean;
  placeholderHeading?: string;
  avatarPrexiewSx?: any;
}

export function RHFUploadAvatar({ name, ...other }: Props) {
  const { control } = useFormContext();

  const { translate } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAvatar
            accept={{
              'image/*': [],
            }}
            error={!!error}
            file={field.value}
            {...other}
          />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {String(translate(error.message))}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

export function RHFUploadBox({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox files={field.value} error={!!error} {...other} />
      )}
    />
  );
}

export function RHFUploadButton({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadButton file={field.value} error={!!error} {...other} />
      )}
    />
  );
}

export function RHFUploadBasic({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();
  const { translate } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBasicInput
          accept={{
            'image/*': [],
          }}
          file={field.value}
          error={!!error}
          helperText={
            (!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ px: 2 }}>
                {error ? String(translate(error?.message)) : helperText}
              </FormHelperText>
            )
          }
          {...other}
        />
      )}
    />
  );
}

export function RHFUpload({
  name,
  multiple,
  helperText,
  placeholderHeading,
  ...other
}: Props) {
  const { control } = useFormContext();
  const { translate } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        multiple ? (
          <Upload
            multiple
            placeholderHeading={placeholderHeading}
            accept={{
              'image/*': [],
            }}
            files={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        ) : (
          <Upload
            placeholderHeading={placeholderHeading}
            file={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? String(translate(error?.message)) : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }
    />
  );
}
